@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply text-base;
}

::-moz-selection {
    color: black;
    background: #ffb5da;
}

::selection {
    color: black;
    background: #ffb5da;
}